#contact{
    flex-direction: row;
    justify-content: space-around;
    flex: 1;
    width: 100%;
    height: 100vh;
    font-size: 1.75vw;

    @media screen and (max-width:810px) {
        min-height: 850px;
    }

}
.body-container{
    height:80%;
}

.head-text{
    padding-top: 2rem;

    @media screen and (max-width:825px) {
        padding-top: 0rem;
        padding-bottom: 2rem;

    }
}

.app__footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--lightGray-color);

    h2 {
        @media screen and (max-width:825px) {
            font-size: x-large;
            margin-top: 2rem;
        }
    }

    .app__footer-cards {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap-reverse;
        gap:2rem;
        

        .app__footer-card {
            min-width: 290px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            margin: 1rem 0;
            padding: 1rem;
            cursor: pointer;
            background-color: var(--lightGray-color);
            border-radius: 10px;

            .icon{
                color: var(--primary-color);
                width: 40px;
                height: 40px;
                margin: 0 0.7rem;
            }

            a {
                width: 100%;
                height: 100%;
                color: var(--primary-color);
                text-decoration: none;
                font-weight: 500;
            }

            &:hover {
                box-shadow: 0 0 25px white;
            }
            
            @media screen and (max-width:450px) {
                width: 100%;
            }
        }

        @media screen and (max-width:825px) {
            width: 100%;
            justify-content: center;
            gap: 1rem;

        }

        @media screen and (max-width:770px) {
            width: 100%;
            justify-content: center;
            gap: 0rem;

        }

    }

    form{
        width: 100%;
    }
}

.app__footer-form{
    flex-direction: column;
    margin: 1rem 2rem;

    div {
        width: 100%;

        margin: 0.75rem 0;
        border-radius: 10px;

        input, textarea {
            margin: 0;
            width: 100%;
            height: 100%;
            padding: 0.95rem;
            border: none;
            border-radius: 7px;
            background-color: var(--lightGray-color);

            font-family: var(--font-base);
            color: var(--primary-color);
            outline: none;
            
        }
        textarea {
            height: 170px;
        }
    }

    button{
        padding: 1rem 2rem;
        border-radius: 10px;
        border: none;
        background-color: var(--secondary-color);

        font-weight: 500;
        color: var(--lightGray-color);
        outline: none;
        margin: 2rem 0 0 0;
        font-family: var(--font-base);
        cursor: pointer;

        @media screen and (max-width:770px) {
            margin: .25rem 0;
        }
    }

    @media screen and (max-width:770px) {
        width: 100%;
        margin: .25rem 0;
    }

}