#projects {
    flex-direction: row;
    justify-content: space-around;
    flex: 1;
    width: 100%;
    font-size: 1.75vw;
    background: var(--gray-color);
    color: var(--primary-color);
}

.projects-list{
    width: 100%;
    flex-direction: column;

    h2{ 
      color: var(--primary-color);

      @media screen and (max-width: 400px) {
        padding-top: 1rem;
    }
    }
}

.projects__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
    .projects__card{
        width: 270px;
        flex-direction: column;

        margin: 2rem;
        padding: 1rem;
        border-radius: .05rem;
        background-color: var(--lightGray-color);
        color: black;

        cursor: pointer;

        &:hover {
            box-shadow: 0 0 25px var(--lightGray-color);
        }

        @media screen and (min-width: 2000px) {
            width: 400px;
            height: 830px;
            padding: 1.25rem;
            border-radius: .075rem;
        }

        @media screen and (max-width: 400px) {
            margin: 1rem;
        }
    }

    @media screen and (min-width: 2000px) {
      margin-top: 3rem;
    }
}

.projects__card-imagecontainer {
    width: 100%;
    height: 230px;

    position: relative;

    img{
        width: 100%;
        height: 100%;
        border-radius: .5rem;
        object-fit: cover;
        
    }

    @media screen and (min-width: 2000px) {
        height: 350px;
    }
}

.app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  
    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;
  
    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
  
      margin: 1rem;
      font-family: var(--font-base);
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease;
  
      svg {
        width: 50%;
        height: 50%;
        color: var(--white-color);
      }
    }
  }

  .projects__work-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
  
    h4 {
      margin-top: 1rem;
      line-height: 1.5;
  
      @media screen and (min-width: 2000px) {
        margin-top: 3rem;
      }
    }
  }