#home {
    position: relative;
    background-color: var(--primary-color);


    color: #edf2f4;

    .app__wrapper {
        padding:0;
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    

    padding: 6rem 2rem 0;


    @media screen and (min-width: 2000px){
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px){
        flex-direction: column;
    }

    @media screen and (max-width: 450px){
        padding: 6rem 1rem 2rem;
    }
}

.header--text {
    text-align: Center;
    z-index: 1;
    position: relative;
    top: -.5%;
    right: -.5%;
    margin-top: -2px;
    transform: rotate(37deg);

    @media screen and (min-width: 2000px){
        font-size: 3vw;
    }

    @media screen and (min-width: 800px){
        font-size: 2vw;
    }

    @media screen and (max-width: 800px){
        font-size: 1.5rem;
        transform: rotate(0deg);
        
    }
}

.logo-icon {
    position: absolute;
    width: 70%;
    z-index: 0;
    @media screen and (max-width: 800px){
        position:relative;
        
    }
}
